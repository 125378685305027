<template>
  <div>
    <div className="header-text">
      {{ form_title }}
    </div>
    <StudentEntryForm
      :form_type="type"
      :form_fields="form_fields"
      :is_private="show_confidentiality"
      @cancel="$router.go(-1)"
      @submit="submit"
      isUpdate="false"
      :submitted="submitted"
    />
  </div>
</template>

<script>
import StudentEntryForm from "@/components/forms/StudentEntryForm";
import { mapGetters, mapState } from "vuex";

export default {
  name: "GenerateForm",
  components: { StudentEntryForm },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      submitted: false,
    };
  },
  methods: {
    submit: function (data) {
      this.submitted = true;
      data.fields.forEach((field) => {
        // if field.value is an array, convert to string
        if (field.value instanceof Array) {
          field.value = field.value.join(",");
        }
      });
      this.$store
        .dispatch("postFieldworkEntry", {
          data: data,
          fieldwork_type_id: this.form_id,
          student_at_level_id: this.student_at_level_id,
        })
        .then(() => {
          this.submitted = false;
          this.$router.push({
            path: "/fieldworks/add/success",
            query: { entry_type: this.form_type, isUpdate: this.isUpdate },
          });
        })
        .catch((err) => {
          console.log("error (postFieldworkEntry):", err);
          this.submitted = false;
        });
    },
  },
  computed: {
    ...mapGetters({ acad_level: "getAcadLevel" }),
    ...mapState({
      forms: (state) => state.lookup_data.fieldwork_types,
    }),
    student_at_level_id: function () {
      return this.acad_level.student_at_level_id;
    },
    ...mapGetters(["student_profile"]),
    form_title: function () {
      // return title of form
      // console.log(this.forms);
      return this.forms.find(
        (form) =>
          form.fieldwork_type_name
            .replace(/\s+/g, "_")
            .replace(/-+/g, "_")
            .toLowerCase() === this.type.toLowerCase()
      ).fieldwork_type_name;
    },
    format_form_label: function () {
      // remove spaces and dashes from form name
      return this.form_title
        .replace(/\s+/g, "")
        .replace(/-+/g, "")
        .toLowerCase();
    },
    form_fields: function () {
      // return fields of form
      let fields = this.forms.find(
        (form) =>
          form.fieldwork_type_name
            .replace(/\s+/g, "_")
            .replace(/-+/g, "_")
            .toLowerCase() === this.type.toLowerCase()
      ).fields;
      // sort fields by display_order property
      fields.sort((a, b) => a.display_order - b.display_order);
      return fields;
    },
    form_id: function () {
      return this.forms.find(
        (form) =>
          form.fieldwork_type_name
            .replace(/\s+/g, "_")
            .replace(/-+/g, "_")
            .toLowerCase() === this.type.toLowerCase()
      ).fieldwork_log_type_id;
    },
    show_confidentiality: function () {
      // return fields of form
      return this.forms.find(
        (form) =>
          form.fieldwork_type_name
            .replace(/\s+/g, "_")
            .replace(/-+/g, "_")
            .toLowerCase() === this.type.toLowerCase()
      ).show_confidentiality;
    },
  },
};
</script>

<style></style>
